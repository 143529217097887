'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

// Any link to this raw URL will trigger the Donation UI rather than the link target.
const HIJACK_URL_REGEX = /^https?:\/\/bestff.kindful.com(?:\/\?campaign=(\d+))?$/;

const InvokeDonation = forgeUiComponent('InvokeDonation', {}, function InvokeDonationConstructor() {
  const self = this;

  Object.defineProperty(this, 'pageCampaign', {
    get: function getPageCampaign() {
      const mainContent = document.querySelector('main');
      const mainCampaign = mainContent && mainContent.getAttribute('data-donation-campaign');
      return mainCampaign || this.element.getAttribute('data-donation-campaign');
    }
  });

  this.element.addEventListener('click', function (e) {
    var detail = {};
    const campaignId = self.pageCampaign;
    if (campaignId) {
      detail = { campaign: campaignId };
    }
    self.dispatchEvent(events.uiWantsDonation, detail);
    e.preventDefault();
  });

  // Also invoke for any link that exactly matches HIJACK_URL
  document.body.addEventListener('click', function (e) {
    const linkTarget = e.target && e.target.closest && e.target.closest('A');
    if (linkTarget && HIJACK_URL_REGEX.test(linkTarget.href)) {
      e.preventDefault();
      const campaignId = RegExp.$1;
      const eventDetail = campaignId ? { campaign: campaignId } : {};
      self.element.dispatchEvent(new CustomEvent(events.uiWantsDonation, {
        bubbles: true,
        detail: eventDetail
      }));
    }
  });
});

module.exports = InvokeDonation;
