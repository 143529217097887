'use strict';

/**
 * Configuration for Getbutter integration
 */
module.exports = Object.freeze({
  accountId: 'qewhS7XC7yux8iGe',
  campaigns: {
    'bffdotfm-website': 'mpn0VL',
    'bffdotfm-podcast': '5j2waL',
    'bffdotfm-bestie': 'ypr9yg'
  },
  defaultCampaign: 'bffdotfm-website'
});
