'use strict';

/** Provides UI for pushstate events */
const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const LOADING_CLASS = 'is-loading';
const LOADED_CLASS = 'has-loaded';
const LOADED_INCREMENTS = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(function (i) {
  return LOADED_CLASS + '-' + i;
});
const LOADED_UNKNOWN = 'unknown';
const LOADED_ALL_CLASSES = LOADED_INCREMENTS.concat(LOADED_CLASS, LOADED_UNKNOWN);

var DynamicDocument = forgeUiComponent('DynamicDocument', {}, function DynamicDocumentConstructor() {
  var self = this;
  var progress = 0;

  Object.defineProperty(this, 'loading', {
    get: function () { return self.element.classList.contains(LOADING_CLASS); },
    set: function (loading) {
      requestAnimationFrame(function () {
        self.element.classList.toggle(LOADING_CLASS, loading);
      });
      this.progress = 0;
    }
  });

  Object.defineProperty(this, 'progress', {
    get: function () { return progress; },
    set: function (newProgress) {
      var roundedValue = newProgress > -1 ? Math.round(newProgress / 10) * 10 : -1;

      if (roundedValue === 0) {
        requestAnimationFrame(function () {
          self.element.classList.remove.apply(self.element.classList, LOADED_ALL_CLASSES);
        });
      } else if (roundedValue === -1) {
        requestAnimationFrame(function () {
          self.element.classList.add(LOADED_CLASS, LOADED_UNKNOWN);
        });
      } else {
        requestAnimationFrame(function () {
          self.element.classList.add(LOADED_CLASS, [LOADED_CLASS, roundedValue].join('-'));
        });
      }
    }
  });

  document.addEventListener(events.navigationRequestStarted, function () {
    self.loading = true;
    self.progress = 10; // Minimum visible value
  });

  document.addEventListener(events.navigationRequestEnded, function (e) {
    if (e.detail.didNavigate) {
      self.progress = 100;
      requestAnimationFrame(function () {
        window.scrollTo(0, 0);
      });
    }
    self.loading = false;
  });

  document.addEventListener(events.navigationRequestProgress, function (e) {
    self.progress = e.detail.percentageComplete;
  });
});

module.exports = DynamicDocument;
