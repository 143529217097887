'use strict';

const givebutter = require('../core/givebutter');
const forgeUiComponent = require('../framework/ui-component');

const GivebutterLinkHandler = forgeUiComponent('GivebutterLinkHandler', {}, function GivebutterLinkHandlerConstructor() {
  document.addEventListener('click', function (e) {
    const linkTarget = e.target && e.target.closest && e.target.closest('A');

    // If there's no link target, ignore:
    if (!linkTarget) { return; }

    // Some other component cancelled the event
    if (e.defaultPrevented) { return; }

    // If the link does not point to a Givebutter URL, ignore:
    if (!givebutter.isCampaign(linkTarget.href)) { return; }

    // If the user is holding down a modifier key for new tab/download behaviour, ignore:
    if (e.ctrlKey || e.shiftKey || e.metaKey || e.altKey) { return; }

    e.preventDefault();

    givebutter.presentCampaign(e.target.href, e.target);
  }, false);
});

// Unlike other UIComponents, links are initialized within the element root,
// so we always return one component that may or may not contain links later.
//
// TODO: DRY: Make this a `Discoverable` mix-in
GivebutterLinkHandler.discover = function (root) {
  return {
    components: [new GivebutterLinkHandler(root)],
    errors: []
  };
};

module.exports = GivebutterLinkHandler;
