'use strict';

const forgeUiComponent = require('../framework/ui-component');
const bffConfig = require('../config/bff');
const events = require('../core/events');

/**
  * Component for showing a stylistically blurred show image, such as in the background of the player bar
  */
var PlaybackArt = forgeUiComponent('PlaybackArt', {
  enabled: 'is-enabled'
}, function PlaybackArtConstructor(el, options, builder) {
  var self = this;
  var backgroundImage = this.queryProperty('image');

  Object.defineProperty(this, 'image', {
    get: function getImage() {
      return backgroundImage;
    }
  });

  builder.defineAttributeProperty('sourceType', 'data-source');
  builder.defineAttributeProperty('width', 'data-width');
  builder.defineAttributeProperty('height', 'data-height');
  builder.defineAttributeProperty('imgSrc', 'data-img-src');

  // Listen for audio playback changes
  if (self.sourceType === 'live') {
    document.addEventListener(events.audioSourceMetadataUpdate, function (e) {
      var detail = e.detail && e.detail;
      if (!detail || detail.src !== bffConfig.liveStreamUrl) {
        return;
      }
      self.updateContent(detail.metadata);
    });
  }

  if (self.sourceType === 'archive') {
    document.addEventListener(events.audioSourceMetadataUpdate, function (e) {
      var detail = e.detail && e.detail;
      if (!detail || detail.src === bffConfig.liveStreamUrl) {
        return;
      }
      self.updateContent(detail.metadata);
    });
  }

  if (self.sourceType === 'page') {
    // Listen for navigation changes for page content
    document.addEventListener(events.pageAudioMetadataUpdate, function (e) {
      var detail = e.detail && e.detail;
      if (!detail) {
        return;
      }
      self.updateContent(detail.metadata);
    });
  }
});

PlaybackArt.prototype.updateContent = function updateContent(metadata) {
  if (metadata.showImage) {
    this.imgSrc = metadata.showImage;
  } else {
    this.imgSrc = false;
  }
  this.refreshImage();
};

PlaybackArt.prototype.refreshImage = function refreshImage() {
  var width = this.width || 1000;
  var height = this.height || 400;

  if (!this.image) {
    return;
  }

  if (!this.imgSrc) {
    this.image.src = '';
    return;
  }

  // TODO: Some sort of Fastly IO helper like we have in Twig would be great here
  // NOTE: We @2x since we don't generate a srcset here
  const ioOpts = ['width=', width * 2, '&height=', height * 2, '&frame=1&blur=50&trim=0.15&quality=60&format=jpeg&enable=upscale&auto=webp&fit=crop'].join('');
  const ioUrl = [this.imgSrc, ioOpts].join((this.imgSrc.indexOf('?') > -1) ? '&' : '?');
  this.image.src = ioUrl;
};

module.exports = PlaybackArt;
