'use strict';

const forgeUiComponent = require('../framework/ui-component');

var ShowsGrid = forgeUiComponent('ShowsGrid', {}, function ShowsGridConstructor() {
  var self = this;
  // Generate background image elements for shows
  [].forEach.call(self.element.querySelectorAll('.program'), self.generateBackgroundImage);
});

ShowsGrid.prototype.generateBackgroundImage = function generateBackgroundImage(showElement) {
  var imageContainer = showElement.querySelector('.image-container');
  var showLogo = imageContainer && imageContainer.querySelector('img');
  var newImg;

  if (!showLogo) return;

  newImg = new Image();
  newImg.src = showLogo.src;
  newImg.alt = '';
  newImg.className = 'ShowsGrid-programImageFill';

  imageContainer.appendChild(newImg);
};

module.exports = ShowsGrid;
