'use strict';

const forgeUiComponent = require('../framework/ui-component');
const events = require('../core/events');

var Hamburger = forgeUiComponent('Hamburger', {
  menuActive: 'is-menuActive'
}, function HamburgerConstructor() {
  var self = this;

  self.element.addEventListener('click', self.handleClick.bind(self));

  document.addEventListener(events.uiMainMenuToggled, function (e) {
    self.menuActive = e.detail.isActive;
  });
});

Hamburger.prototype.triggerToggle = function triggerToggle() {
  this.element.dispatchEvent(new CustomEvent(events.uiMainMenuToggled, {
    bubbles: true,
    detail: { isActive: !this.menuActive }
  }));
};

Hamburger.prototype.handleClick = function handleClick(e) {
  e.preventDefault();
  this.triggerToggle();
};

module.exports = Hamburger;
