'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const PodcastInfo = forgeUiComponent('PodcastInfo', {
  subscribing: 'is-subscribing'
}, function PodcastInfoConstructor() {
  const self = this;
  const subscribeButton = this.element.querySelector('.PodcastInfo-subscribeButton') || document.createElement('div');

  this.element.addEventListener(events.uiModalDismiss, function () {
    self.subscribing = false;
  });

  subscribeButton.addEventListener('click', function openSubscribeDialog(e) {
    e.preventDefault();
    self.subscribing = true;
  });
});

module.exports = PodcastInfo;
