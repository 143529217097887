'use strict';

const addKeyPressEventListener = require('../helpers/addkeypresseventlistener');
const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const Modal = forgeUiComponent('Modal', {}, function ModalConstructor() {
  const self = this;
  const content = this.queryProperty('content');
  const closeButton = this.queryProperty('close');

  function dispatchDismiss() {
    self.element.dispatchEvent(new CustomEvent(events.uiModalDismiss, {
      bubbles: true
    }));
  }

  closeButton.addEventListener('click', function (e) {
    e.stopPropagation();
    e.preventDefault();
    dispatchDismiss();
  });

  this.element.addEventListener('click', function (e) {
    if (content === e.target || content.contains(e.target)) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    dispatchDismiss();
  });

  addKeyPressEventListener(window, 27, function () { // ESC key
    dispatchDismiss();
  });

  document.addEventListener(events.navigationRequestEnded, function dismissAfterNavigate() {
    dispatchDismiss();
  });
});

module.exports = Modal;
