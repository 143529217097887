'use strict';

const domready = require('../helpers/domready');
const forgeUiComponent = require('../framework/ui-component');
const addScrollEventListener = require('../helpers/addscrolleventlistener');
const addKeyPressEventListener = require('../helpers/addkeypresseventlistener');
const events = require('../core/events');

const MENU_ELEMENT_SELECTOR = '.FixedGlobalNav-mainMenu';

var FixedGlobalNav = forgeUiComponent('FixedGlobalNav', {
  scrolled: 'is-scrolled',
  menuShowing: 'is-menuShowing'
}, function FixedGlobalNavConstructor() {
  var self = this;
  var menuElement = self.element.querySelector(MENU_ELEMENT_SELECTOR);

  if (menuElement) {
    menuElement.addEventListener('click', function (e) {
      e.target.dispatchEvent(new CustomEvent(events.uiMainMenuToggled, {
        bubbles: true,
        detail: { isActive: false }
      }));
    });
  }

  domready(function checkInitialScrollPosition() {
    var lastScrollPosition = window.scrollY || document.body.scrollTop;
    self.updateScroll(lastScrollPosition);
  });

  addScrollEventListener(window, function () {
    var lastScrollPosition = window.scrollY || document.body.scrollTop;
    self.updateScroll(lastScrollPosition);
  });

  addKeyPressEventListener(window, 27, function (e) { // ESC key
    e.target.dispatchEvent(new CustomEvent(events.uiMainMenuToggled, {
      bubbles: true,
      detail: { isActive: false }
    }));
  });

  self.element.addEventListener(events.uiMainMenuToggled, self.handleMenuToggle.bind(self));
});

FixedGlobalNav.prototype.updateScroll = function updateScroll(position) {
  if (position > 0 && !this.scrolled) {
    this.scrolled = true;
  }
  if (position < 1 && this.scrolled) {
    this.scrolled = false;
  }
};

FixedGlobalNav.prototype.handleMenuToggle = function handleMenuToggle(ev) {
  this.menuShowing = ev.detail.isActive;
};

module.exports = FixedGlobalNav;
