'use strict';

const A_MINUTE = 60;
const AN_HOUR = 60 * 60;

function pad(number, magnitude) {
  const stringNum = ('' + Math.abs(number)).split('').reverse();

  return Array.apply(null, Array(magnitude)).map(function (p, index) {
    return stringNum[index] || '0';
  }).concat(number < 0 ? '-' : '')
    .reverse()
    .join('');
}

function formatTime(time) {
  time = Math.floor(time);

  const hours = Math.floor(time / AN_HOUR);
  const remainingSeconds = time % (AN_HOUR);

  const minutes = Math.floor(remainingSeconds / A_MINUTE);
  const seconds = remainingSeconds % A_MINUTE;

  return (hours > 0 ? [hours, pad(minutes, 2), pad(seconds, 2)] : [minutes, pad(seconds, 2)]).join(':');
}

module.exports = {
  pad: pad,
  formatTime: formatTime
};
