'use strict';

function loadFonts() {
  Array.prototype.forEach.call(document.querySelectorAll('link.fontstylesheet'), function (link) {
    link.rel = 'stylesheet';
  });
}

module.exports = {
  loadFonts: loadFonts
};
