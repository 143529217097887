'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const TabPane = forgeUiComponent('TabPane', {}, function TabPaneConstructor(element, options, builder) {
  var self = this;

  builder.defineClassState('active', 'is-activeTab');
  builder.defineAttributeProperty('tabGroup', 'data-tab-group');
  builder.defineAttributeProperty('id', 'id');

  document.addEventListener(events.uiActivateTab, function listenForFocus(e) {
    var detail = e && e.detail;

    if (!detail) {
      return;
    }
    if (detail.group !== self.tabGroup) {
      return;
    }
    self.active = detail.tabId === self.id;

    if (self.active) {
      self.dispatchEvent(events.uiTabActivated, {
        tabId: self.id,
        group: self.tabGroup
      }, e);
    }
  });
});

module.exports = TabPane;
