'use strict';

const forgeUiComponent = require('../framework/ui-component');

var ScheduleNavigation = forgeUiComponent('ScheduleNavigation', {
  browsingGenre: 'is-browsingGenre'
}, function ScheduleNavigationConstructor() {
  const self = this;
  var genreLink = this.element.querySelector('.ScheduleNavigation-genreLink') || document.createElement('a');

  // TODO: Reset scroll position of submenus

  genreLink.addEventListener('click', function (event) {
    event.preventDefault();
    self.browsingGenre = !self.browsingGenre;
  });
});

module.exports = ScheduleNavigation;
