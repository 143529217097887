'use strict';

/**
 * Core functionality for working with Givebutter, which powers our donation flow
 * including mappings between known campaign URLs and embeddable 'element' forms.
 */
const config = require('../config/givebutter');
const events = require('../core/events');

const ELEMENT_SCRIPT_ID = 'givebutter-js';
const CAMPAIGN_PATTERN = /^[\w-]+$/i;
const URL_PATTERN = /^https?:\/\/givebutter\.com\/(?:c\/)?([\w-]+)/i;
const IGNORE_PATTERN = /#no-modal$/i; // URLs can be tagged to bypass this system

// Pop-up window configuration
const WINDOW_WIDTH = 780;
const WINDOW_HEIGHT = 700;
const CORE_POPUP_OPTIONS = [
  'resizable',
  'width=' + WINDOW_WIDTH,
  'height=' + WINDOW_HEIGHT,
  'alwaysRaised'
];

/**
 * Load the Givebutter Elements JavaScript API on demand
 */
function elementsApi() {
  // Don't re-init if script already appended
  if (window.Givebutter) {
    return window.Givebutter.apply(window.Givebutter, arguments);
  }

  if (!document.getElementById(ELEMENT_SCRIPT_ID)) {
    const head = document.querySelector('head');
    const js = document.createElement('script');
    js.id = ELEMENT_SCRIPT_ID;
    js.async = true;
    js.src = 'https://js.givebutter.com/elements/latest.js';
    head.appendChild(js);
  }

  window.Givebutter = window.Givebutter || function () {
    (window.Givebutter.q = window.Givebutter.q || []).push(arguments);
  };
  window.Givebutter.l = +new Date();

  // Initialize with our account ID
  window.Givebutter('setOptions', {
    accountId: config.accountId,
    bubble: false
  });

  return window.Givebutter.apply(window.Givebutter, arguments);
}

function parseCampaignId(urlOrId) {
  if (IGNORE_PATTERN.test(urlOrId)) {
    return false;
  }
  if (CAMPAIGN_PATTERN.test(urlOrId)) {
    return urlOrId;
  }
  if (URL_PATTERN.test(urlOrId)) {
    return RegExp.$1;
  }
}

function getFormForCampaign(idOrUrl) {
  const campaignId = parseCampaignId(idOrUrl);
  if (!campaignId) {
    return;
  }
  return config.campaigns[campaignId] && campaignId;
}

function isCampaign(idOrUrl) {
  return !!parseCampaignId(idOrUrl);
}

function isEmbeddable(idOrUrl) {
  return !!getFormForCampaign(idOrUrl);
}

function applePayAvailable() {
  try {
    return !!(window.ApplePaySession && window.ApplePaySession.canMakePayments());
  } catch (e) {
    // InvalidAccessError will be thrown when run on insecure/localhost document
    return false;
  }
}

/**
 * Present a Givebutter donation campaign in the most appropriate way, either popping open a window,
 * or triggering our local modal.
 * @param {string} urlOrId - Either the URL to a Givebutter campaign, or the public-facing campaign ID
 */
function presentCampaign(url, sender, forcePopup) {
  sender = sender || document.body;

  if (!forcePopup && !applePayAvailable() && isEmbeddable(url)) {
    setLocalTracking();

    // Close any existing modals before presenting the new one
    sender.dispatchEvent(new CustomEvent(events.uiPresentDonationCampaign, {
      detail: {
        campaignId: parseCampaignId(url),
        campaignUrl: url
      },
      bubbles: true
    }));

    return;
  }
  const popupWindow = popup(hydrateCampaignUrl(url));
  const listenForClose = window.setInterval(function isPopupClosed() {
    if (popupWindow.closed) {
      window.clearInterval(listenForClose);
      sender.dispatchEvent(new CustomEvent(events.donationEnded, { bubbles: true }));
      sender.dispatchEvent(new CustomEvent(events.uiModalDismiss, { bubbles: true }));
    }
  }, 500);
}

function getMainAttribute(attr) {
  const mainContent = document.querySelector('main');
  return mainContent && mainContent.getAttribute('data-' + attr);
}

function getTrackingParameters() {
  const djAttr = getMainAttribute('donation-dj-designation');
  const showAttr = getMainAttribute('donation-show-designation');
  var params = {
    utm_source: 'web',
    utm_campaign: getMainAttribute('donation-id')
  };

  if (djAttr) {
    params.utm_content = 'DJ: ' + djAttr;
  } else if (showAttr) {
    params.utm_content = 'Show: ' + showAttr;
  } else {
    params.utm_content = '';
  }

  return params;
}

function setLocalTracking() {
  var params = getTrackingParameters();
  Object.keys(params).forEach(function (k) {
    elementsApi(function () {
      // Since these are stored for the length of the session, we must blank out unset values
      // in case they were set during a previous invocation of the donation flow on a different
      // page.
      window.Givebutter('analytics.set', k, params[k] || '');
    });
  });
}

function hydrateCampaignUrl(baseUrl) {
  // Add gba_ tracking params to URL
  var params = getTrackingParameters();
  const serializedParams = Object.keys(params).map(function (k) {
    if (params[k]) {
      return [k, encodeURIComponent(params[k])].join('=');
    }
    return false;
  }).filter(function (p) { return !!p; }).join('&');
  return [baseUrl, serializedParams].join('?');
}

function popup(url) {
  const posX = ((window.outerWidth / 2) + window.screenX) - Math.floor(WINDOW_WIDTH / 2);
  const posY = window.screenY + 40;

  const finalFeatures = CORE_POPUP_OPTIONS.concat([
    'left=' + posX,
    'top=' + posY
  ]).join(',');

  return window.open(url, '_blank', finalFeatures);
}

module.exports = Object.freeze({
  getCampaignForUrl: parseCampaignId,
  getFormForCampaign: getFormForCampaign,
  isCampaign: isCampaign,
  isEmbeddable: isEmbeddable,
  isApplePayAvailable: applePayAvailable,
  presentCampaign: presentCampaign,
  elementsApi: elementsApi
});
