'use strict';

const forgeUiComponent = require('../framework/ui-component');

const ContentCycle = forgeUiComponent('ContentCycle', {}, function ContentCycleConstructor(el, opts, builder) {
  var selectedIndex = -1;
  const activeClass = 'is-active';
  const items = this.queryPropertyAll('item');

  builder.defineAttributeProperty('interval', 'data-cycle-interval');

  window.setInterval(function () {
    if (selectedIndex > -1) {
      items[selectedIndex].classList.remove(activeClass);
    }
    selectedIndex += 1;

    if (selectedIndex >= items.length) {
      selectedIndex = 0;
    }
    items[selectedIndex].classList.add(activeClass);
  }, this.interval * 1000);
});

module.exports = ContentCycle;
