'use strict';

/** Enable interactive edit buttons if a user is authorized to access the content */
const forgeUiComponent = require('../framework/ui-component');

const ACCESS_API_PATH = '/admin/access';

var EditButton = forgeUiComponent('EditPageLink', {}, function PlaybackInvokerConstructor(element, opts, builder) {
  builder.defineClassState('authed', 'is-authed');

  Object.defineProperty(this, 'url', {
    get: function getUrl() {
      return [ACCESS_API_PATH, this.model, this.id].join('/');
    }
  });

  Object.defineProperty(this, 'model', {
    get: function getModel() {
      return this.element.getAttribute('data-model');
    }
  });

  Object.defineProperty(this, 'id', {
    get: function getId() {
      return this.element.getAttribute('data-id');
    }
  });

  if (this.valid()) {
    this.checkAuth();
  }
});

EditButton.prototype.valid = function isValid() {
  return this.model && (this.id > 0);
};

EditButton.prototype.checkAuth = function checkAuth() {
  var self = this;
  window.fetch(this.url, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow'
  }).then(function (rsp) {
    return rsp.json().then(function (data) {
      return rsp.ok && data.authorized;
    });
  }).then(function (result) {
    self.authed = result;
  });
};

module.exports = EditButton;
