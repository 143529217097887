'use strict';

const forgeUiComponent = require('../framework/ui-component');

const ClampedContent = forgeUiComponent('ClampedContent', {
  expanded: 'is-expanded'
}, function ClampedContentConstructor() {
  const self = this;
  const expandButton = this.element.querySelector('.ClampedContent-showMoreButton') || document.createElement('div');

  expandButton.addEventListener('click', function releaseClamp(e) {
    e.preventDefault();
    self.expanded = true;
  });
});

module.exports = ClampedContent;
