'use strict';

/** Event listener for Google Analytics & Tag Manager */
const events = require('../core/events');
const EventTiming = require('../helpers/eventtiming');
const ga4 = require('ga-gtag');

const TIMING_INCREMENT = 30;

const MEASUREMENT_ID = 'G-QX1EDS97TG';
const GTAG_ID = 'GTM-5B4BJ7V';

var listeningTimestamps = {};

// Track total listening time: Array of start + current times.
const musicTiming = new EventTiming();
var lastTotalMusic = 0;

function AnalyticsMonitor() {
  // TODO: Pull Google Analytics ID from mark-up?
  ga4.install(MEASUREMENT_ID, { send_page_view: false });
  this.addTagManager();

  document.addEventListener('click', this.handleAllClicks);

  document.addEventListener(events.navigationRequestEnded, this.handleNavigationRequestEnded);
  document.addEventListener(events.uiDidSearch, this.handleSearch);

  document.addEventListener(events.audioSourcePlaying, this.handleMusicStart);
  document.addEventListener(events.audioSourcePaused, this.handleMusicPause);
  document.addEventListener(events.audioSourceEnded, this.handleMusicComplete);
  document.addEventListener(events.audioSourceTimeUpdate, this.handleMusicProgress);

  // Log Initial Page Impression
  this.initialImpression();
}

function gaEvent(categoryName, action, options) {
  options = options || {};
  options.eventCategory = categoryName;
  return ga4.gtag('event', action, options);
}

function gaPageView(url, title) {
  return gaEvent(null, 'page_view', {
    page_location: url,
    page_title: title
  });
}

AnalyticsMonitor.prototype.addTagManager = function addTagManager() {
  const insertAt = document.getElementsByTagName('script')[0];
  const tagScript = document.createElement('script');
  const initialEvent = {
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(initialEvent);

  tagScript.async = true;

  // TODO: Pull tag manager ID from mark-up
  tagScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + GTAG_ID;
  insertAt.parentNode.insertBefore(tagScript, insertAt);
};

AnalyticsMonitor.prototype.initialImpression = function initialImpression() {
  const newPath = window.location.href;
  ga4.gtag('set', { page: newPath });
  gaPageView(newPath);
};

AnalyticsMonitor.prototype.legacyBrowserImpression = function legacyBrowserImpression() {
  gaEvent('browser', 'legacyBrowserPageView');
};

AnalyticsMonitor.prototype.handleAllClicks = function handleAllClicks(e) {
  var payload = {};
  // HACK: Sentry is reporting a lot of errors with the `closest` function. Add guards.
  // TODO: Log info about the cases when `closest` doesn't exist.
  const linkTarget = (e.target && e.target.closest) && (e.target.closest('A') || e.target.closest('BUTTON'));

  if (!linkTarget) { return; }

  const action = linkTarget.getAttribute('data-analytics-clickaction') || linkTarget.tagName === 'A' ? 'link' : 'button';

  const label = linkTarget.getAttribute('data-analytics-label') || linkTarget.href;

  if (!label) { return; }

  payload = { eventLabel: label };
  const value = linkTarget.getAttribute('data-analytics-value') || undefined;

  if (!Number.isNaN(value)) {
    payload.eventValue = value;
  }

  gaEvent('click', action, payload);
};

AnalyticsMonitor.prototype.handleNavigationRequestEnded = function onNavigationRequestEnded(e) {
  const newPath = window.location.href;
  ga4.gtag('set', { page_location: newPath });

  if (e.detail && e.detail.didNavigate) {
    gaPageView(newPath);
  } else {
    gaEvent('error', 'navigation', {
      eventLabel: e.detail && e.detail.url
    });
  }
};

AnalyticsMonitor.prototype.handleSearch = function handleSearch(e) {
  gaEvent('search', 'search', {
    eventLabel: e.detail && e.detail.query
  });
};

AnalyticsMonitor.prototype.handleMusicStart = function onMusicStart(e) {
  if (!e.detail) { return; }

  musicTiming.start();
  gaEvent('music', 'play', {
    eventLabel: e.detail.src
  });
};

AnalyticsMonitor.prototype.handleMusicPause = function onMusicPause(e) {
  if (!e.detail) { return; }

  musicTiming.extend();
  gaEvent('music', 'pause', {
    eventLabel: e.detail.src,
    eventValue: Math.floor(e.detail.time)
  });
};

AnalyticsMonitor.prototype.handleMusicComplete = function onMusicEnd(e) {
  if (!e.detail) { return; }

  musicTiming.extend();
  gaEvent('music', 'ended', {
    eventLabel: e.detail.src,
    eventValue: Math.floor(e.detail.time)
  });
};

AnalyticsMonitor.prototype.handleMusicProgress = function onMusicProgress(e) {
  musicTiming.extend();

  const trackSrc = e.detail && e.detail.src;
  if (trackSrc) {
    const time = e.detail.time;
    const windowedTime = Math.floor(time / TIMING_INCREMENT) * TIMING_INCREMENT;

    if (windowedTime > (listeningTimestamps[trackSrc] || 0)) {
      listeningTimestamps[trackSrc] = windowedTime;

      gaEvent('music', 'listening', {
        eventLabel: trackSrc,
        eventValue: windowedTime,
        nonInteraction: true
      });
    }
  }

  const windowedTotalMusic = Math.floor(musicTiming.total / TIMING_INCREMENT) * TIMING_INCREMENT;
  if (windowedTotalMusic > lastTotalMusic) {
    lastTotalMusic = windowedTotalMusic;
    gaEvent('music', 'total', {
      eventLabel: 'Session Total Music Playback',
      eventValue: Math.floor(windowedTotalMusic),
      nonInteraction: true
    });
  }
};

module.exports = AnalyticsMonitor;
