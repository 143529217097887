'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const TabLink = forgeUiComponent('TabLink', {}, function TabLinkConstructor(element, options, builder) {
  var self = this;

  builder.defineClassState('selected', 'is-tabSelected');
  builder.defineAttributeProperty('tabGroup', 'data-tab-group');
  builder.defineAttributeProperty('target', 'href');

  Object.defineProperty(this, 'targetId', {
    get: function getTargetId() {
      var href = this.element.href || '';
      var fragment = href && href.split('#').pop();
      return fragment || this.element.getAttribute('data-target');
    }
  });

  document.addEventListener(events.uiTabActivated, function listenForFocus(e) {
    var detail = e && e.detail;
    if (!detail) {
      return;
    }
    self.selected = detail.tabId === self.targetId;
  });

  this.element.addEventListener('click', function click(e) {
    e.preventDefault();
    self.dispatchEvent(events.uiActivateTab, {
      tabId: self.targetId,
      group: self.tabGroup
    }, e);
  });
});

module.exports = TabLink;
