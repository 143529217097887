'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const DonationConfirmation = forgeUiComponent('DonationConfirmation', {
  showing: 'is-showing',
  rewarding: 'is-rewarding'
}, function DonationConfirmationConstructor() {
  const self = this;

  const rewardOptions = this.element.querySelectorAll('.DonationConfirmation-rewardOption') || document.createElement('div');
  const rewardAmountElement = this.element.querySelector('.DonationConfirmation-rewardAmount') || document.createElement('span');

  var lastDonationAmount = 0;

  Object.defineProperty(this, 'rewardAmountText', {
    get: function getRewardAmount() {
      return rewardAmountElement.textContent || '';
    },
    set: function setRewardAmount(text) {
      if (text) {
        rewardAmountElement.textContent = '$' + text;
        this.rewarding = true;
      } else {
        rewardAmountElement.textContent = '';
        this.rewarding = false;
      }
    }
  });

  Object.defineProperty(this, 'lastDonation', {
    get: function getLastDonation() {
      return lastDonationAmount;
    }
  });

  document.addEventListener(events.donationSuccess, function () {
    self.showConfirmation();
  }, false);

  document.addEventListener(events.donationStarted, function (e) {
    if (e.detail && e.detail.amount && !Number.isNaN(e.detail.amount)) {
      lastDonationAmount = +e.detail.amount;

      self.rewardAmountText = lastDonationAmount;

      /* hide reward option buttons */
      Array.prototype.forEach.call(rewardOptions, function (element) {
        requestAnimationFrame(function () {
          element.classList.add('u-hidden');
        });
      });

      /* show the reward matching the closest tier */
      const itemAmountTier = Array.prototype.map.call(rewardOptions, function (element) {
        return element.getAttribute('data-reward-amount');
      }).filter(function (item) { return !!item; })
        .sort(function (a, b) { return +a > +b; })
        .reduce(function (currentValue, optionValue) {
          if (lastDonationAmount >= optionValue) {
            return optionValue;
          } else {
            return currentValue;
          }
        }, 0);

      const itemTierElement = self.element.querySelector('.DonationConfirmation-rewardOption[data-reward-amount="' + itemAmountTier + '"]');
      if (itemTierElement) {
        self.rewarding = true;
        requestAnimationFrame(function () {
          itemTierElement.classList.remove('u-hidden');
        });
      } else {
        self.rewarding = false;
      }
    } else {
      self.rewarding = false;
      lastDonationAmount = 0;
    }
  });

  this.element.addEventListener(events.uiModalDismiss, function () {
    self.showing = false;
  });
});

DonationConfirmation.prototype.showConfirmation = function showConfirmation() {
  this.showing = true;
};

module.exports = DonationConfirmation;
