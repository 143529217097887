'use strict';

var compatibility = require('./helpers/compatibility');

var events = require('./core/events');
var domReady = require('./helpers/domready');

var Analytics = require('./core/analytics');
var Sentry = require('./core/sentry');
var Development = require('./core/dev');

var Navigation = require('./core/navigation');
var RegionRefresh = require('./ui/region-refresh');
var JavaScriptLinkHack = require('./ui/javascript-link-hack');

var PlaybackInfo = require('./ui/playback-info');
var PlaybackArt = require('./ui/playback-art');
var LiveStreamContextBar = require('./ui/live-stream-context-bar');
var PageAudioPlayer = require('./ui/page-audio-player');
var ContentCycle = require('./ui/content-cycle');

var Modal = require('./ui/modal');
var ClampedContent = require('./ui/clamped-content');

var DynamicDocument = require('./ui/dynamicdocument');
var BffMenuBar = require('./ui/bffmenubar');
var FixedGlobalNav = require('./ui/fixedglobalnav');
var Hamburger = require('./ui/hamburger');
var Totaliser = require('./ui/totaliser');

var SiteSearch = require('./ui/sitesearch');

var SlideDeck = require('./ui/slidedeck');
var ScheduleNavigation = require('./ui/schedulenavigation');
var ShowsGrid = require('./ui/showsgrid');
var TabLink = require('./ui/tab-link');
var TabPane = require('./ui/tab-pane');

var Twitter = require('./ui/twitter');
var SharePopup = require('./ui/sharepopup');
var DonationPrompt = require('./ui/donation-prompt');
var DonationConfirmation = require('./ui/donation-confirmation');
var GivebutterDonationForm = require('./ui/givebutter-donation-form');
var GivebutterLinkHandler = require('./ui/givebutter-link-handler');
var InvokeDonation = require('./ui/invokedonation');
var NewsletterLinkHandler = require('./ui/newsletter-link');

// TODO: PodcastInfo, AddReminder, AddFeed could be merged
var PodcastInfo = require('./ui/podcast-info');
var PodcastSubscribe = require('./ui/podcast-subscribe');
var AddReminder = require('./ui/add-reminder');
var AddFeed = require('./ui/add-feed');
var SubscribeDialog = require('./ui/subscribe-dialog');

var EditButton = require('./ui/edit-button');

var fontHelper = require('./helpers/fontstylesheet');

// Analytics & Error Logging
var analytics = new Analytics();
new Sentry();

// Helpers if we're in dev mode
new Development();

// Init Components
function initGlobalComponents() {
  const html = document.body.parentElement;
  const body = document.body;
  const navManager = new Navigation(document.body);
  navManager.contentSelector = '.DynamicDocument-content';

  // Lazy-load Flama to prevent FOIT
  fontHelper.loadFonts();

  DynamicDocument.discover(html);
  FixedGlobalNav.discover(html);
  RegionRefresh.discover(html);
  JavaScriptLinkHack.discover(html);

  Hamburger.discover(body);
  BffMenuBar.discover(body);
  PlaybackInfo.discover(body);
  PlaybackArt.discover(body);
  LiveStreamContextBar.discover(body);
  SiteSearch.discover(body);
  DonationPrompt.discover(body);
  DonationConfirmation.discover(body);
  GivebutterDonationForm.discover(body);
  GivebutterLinkHandler.discover(body);
  NewsletterLinkHandler.discover(body);
  Totaliser.discover(body);
}

function initContentComponents() {
  var body = document.body;

  SlideDeck.discover(body);
  ShowsGrid.discover(body);
  ScheduleNavigation.discover(body);
  Modal.discover(body);
  ClampedContent.discover(body);
  ContentCycle.discover(body);

  Twitter.discover(body);
  InvokeDonation.discover(body);
  SharePopup.discover(body);

  TabLink.discover(body);
  TabPane.discover(body);

  PodcastInfo.discover(body);
  PodcastSubscribe.discover(body);
  SubscribeDialog.discover(body);
  AddReminder.discover(body);
  AddFeed.discover(body);

  PageAudioPlayer.discover(body);

  EditButton.discover(body);
}

// Don't initialize app if browsing is missing features
if (compatibility()) {
  // TODO: Add “discover” to UI components.
  domReady(function () {
    initGlobalComponents();
    initContentComponents();
  });

  document.addEventListener(events.navigationRequestEnded, function (e) {
    if (e.detail.didNavigate) {
      initContentComponents();
    }
  });
} else {
  analytics.legacyBrowserImpression();
  document.body.className += ' u-noJs';
}
