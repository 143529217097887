'use strict';

const domready = require('../helpers/domready');
const forgeUiComponent = require('../framework/ui-component');
const addScrollEventListener = require('../helpers/addscrolleventlistener');
const BffLogo = require('./bfflogo');

var BffMenuBar = forgeUiComponent('BFFMenuBar', {
  collapsed: 'is-collapsed'
}, function BffMenuBarConstructor() {
  var self = this;

  self.options.collapseThreshold = self.collapseThreshold || 100;
  self.options.minExpandedHeight = self.minExpandedHeight || 450;

  // TODO: This is v. broken encapsulation?
  this.logo = new BffLogo(self.element.querySelector('.BFFLogo'));

  addScrollEventListener(window, function () {
    self.handleEvent();
  });

  domready(function () { self.handleEvent(); });
});

BffMenuBar.prototype.handleEvent = function handleEvent() {
  const contentHeight = window.innerHeight;
  const forceCollapse = contentHeight < this.options.minExpandedHeight;
  const scrollPosition = window.scrollY || document.body.scrollTop;

  this.updateScroll(scrollPosition, forceCollapse);
};

BffMenuBar.prototype.updateScroll = function updateScroll(position, collapse) {
  if ((collapse || position > this.options.collapseThreshold) && !this.collapsed) {
    this.collapsed = true;
    this.logo.minimized = true;
  }
  if ((!collapse && position < this.options.collapseThreshold) && this.collapsed) {
    this.collapsed = false;
    this.logo.minimized = false;
  }
};

module.exports = BffMenuBar;
