'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');
const htmlPage = require('../data/htmlPage');

var Totaliser = forgeUiComponent('Totaliser', null, function TotaliserConstructor(rootElement, options, componentBuilder) {
  const self = this;
  const remainingElement = this.queryProperty('targetRemaining');
  const raisedElement = this.queryProperty('totalRaised');

  var targetAmount = 0;
  var raisedAmount = 0;

  componentBuilder.defineClassState('overFunded', 'is-funded-over');
  componentBuilder.defineClassState('dataUpdated', 'is-updated');

  Object.defineProperty(this, 'amountTargetted', {
    get: function getTargetAmount() { return targetAmount; },
    set: function setTargetAmount(amount) {
      if (Number.isNaN(amount)) {
        return;
      }
      targetAmount = amount;
    }
  });

  Object.defineProperty(this, 'amountRaised', {
    get: function getAmountRaised() { return raisedAmount; },
    set: function setAmountRaised(amount) {
      if (Number.isNaN(amount)) {
        return;
      }
      raisedAmount = amount;
      raisedElement.textContent = '$' + amount;
    }
  });

  Object.defineProperty(this, 'amountRemaining', {
    set: function setAmountRemaining(amount) {
      if (Number.isNaN(amount)) {
        return;
      }
      remainingElement.textContent = '$' + amount;
      this.overFunded = amount < 0;
    }
  });

  componentBuilder.defineAttributeProperty('totaliserValuesUrl', 'data-values-href', function () {
    return false;
  });

  componentBuilder.defineAttributeProperty('fundingLevel', 'data-funded', function (val) {
    if (!Number.isNaN(val) && (val % 5 === 0)) {
      if (val > 100) {
        return 'over';
      } else {
        return val;
      }
    } else {
      return 0;
    }
  });

  document.addEventListener(events.dataHtmlPage, function (e) {
    /* Creek doesn't have a metadata API today so we're hacking data into a secret page.
     * The title will be in the format RAISED/TOTAL, and the Totaliser content updated
     * using those values */
    if (e.detail && e.detail.querySelector) {
      const fundraiserDataElement = e.detail.querySelector('.Article-title');
      const data = fundraiserDataElement.textContent || '';
      const parts = data.trim().split('/');
      self.updateValues(parts[0], parts[1]);
    }
  });

  htmlPage.fetch(this.totaliserValuesUrl);
});

Totaliser.prototype.updateValues = function updateValues(raised, target) {
  this.amountTargetted = target;
  this.amountRaised = raised;
  this.amountRemaining = target - raised;

  const perCent = (raised / target) * 100;
  const perCentRounded = Math.floor(perCent / 5) * 5;
  this.fundingLevel = perCentRounded;

  this.dataUpdated = true;
};

module.exports = Totaliser;
