'use strict';

/** Wrapper for Twitter widgets.js */

const TWITTER_SCRIPT_ID = 'twitter-wjs';
const TWITTER_EMBED_CODE_SELECTORS = [
  'a.twitter-share-button',
  'a.twitter-hashtag-button',
  'a.twitter-follow-button',
  'blockquote.twitter-tweet',
  'a.twitter-timeline'
].join(',');

function TwitterWidgets(root) {
  // Don't load Twitter script until we're on a page that includes widgets
  if (!root.querySelector(TWITTER_EMBED_CODE_SELECTORS)) {
    return;
  }

  /* Automatically pull in Twitter script */
  window.twttr = (function () {
    var js;
    var meta;
    var twttr = window.twttr || {};

    if (document.getElementById(TWITTER_SCRIPT_ID)) { return twttr; }

    const head = document.querySelector('head');

    meta = document.createElement('meta');
    meta.name = 'twitter:widgets:autoload';
    meta.content = 'off';

    // TODO: Pull this from a config somehow
    meta = document.createElement('meta');
    meta.name = 'twitter:widgets:link-color';
    meta.content = '#69C1BB';

    js = document.createElement('script');
    js.id = TWITTER_SCRIPT_ID;
    js.async = true;
    js.src = 'https://platform.twitter.com/widgets.js';

    head.appendChild(meta);
    head.appendChild(js);

    /* eslint-disable no-underscore-dangle */
    twttr._e = [];
    twttr.ready = function (f) { twttr._e.push(f); };
    /* eslint-enable no-underscore-dangle */

    return twttr;
  }());

  this.call(function (twttr) { twttr.widgets.load(root); });
}

TwitterWidgets.prototype.call = function callWidgets(callback) {
  window.twttr.ready(callback);
};

// Unlike other UIComponents, Twitter widgets are initialized within the element root,
// so we always return one component that may or may not contain widgets later.
TwitterWidgets.discover = function (root) {
  return {
    components: [new TwitterWidgets(root)],
    errors: []
  };
};

module.exports = TwitterWidgets;
