'use strict';

const forgeUiComponent = require('../framework/ui-component');
const events = require('../core/events');

var LiveStreamContextBar = forgeUiComponent('LiveStreamContextBar', {
  playing: 'is-playing'
}, function LiveStreamContextBarConstructor(/* el, options, builder */) {
  var self = this;

  // Listen for MusicPlayer.js events
  document.addEventListener(events.uiMediaStateChange, function (e) {
    if (!e.detail) {
      return;
    }
    // Ignore non play/pause states
    if (e.detail.mediaState !== 'playing' && e.detail.mediaState !== 'paused') {
      return;
    }

    // Verify this event is for a parent-component of this element
    if (!e.target.contains(self.element)) {
      return;
    }

    // Check explicit states because we're ignored progress events
    if (e.detail.mediaState === 'playing') {
      self.playing = true;
    }
    if (e.detail.mediaState === 'paused') {
      self.playing = false;
    }
  });
});

module.exports = LiveStreamContextBar;
