'use strict';

const butter = require('../core/givebutter');
const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const GivebutterDonationForm = forgeUiComponent('GivebutterDonationForm', {
  donating: 'is-donating',
  formInitialized: 'is-formInitialized'
}, function GivebutterDonationFormConstructor() {
  const self = this;

  document.addEventListener(events.uiPresentDonationCampaign, function (e) {
    self.init(e.detail && e.detail.campaignId);
  }, false);

  this.element.addEventListener(events.uiModalDismiss, function () {
    self.donating = false;
    self.cleanUp();
  }, false);

  // Listen for an events from Givebutter elements in order to trigger post-donation flows
  window.addEventListener('message', function (ev) {
    if (ev.data && ev.data.givebutter) {
      const eventSender = self.queryProperty('container');

      if (ev.data.event === 'donation.complete') {
        // Close self and invoke local donation success screen instead
        self.dispatchEvent(events.uiModalDismiss, null, eventSender, true);
        self.dispatchEvent(events.donationEnded, null, eventSender, true);
        self.dispatchEvent(events.donationSuccess, null, eventSender, true);
      }
    }
  }, false);
});

GivebutterDonationForm.prototype.init = function initializeForm(elementId) {
  const self = this;
  const formContainer = this.queryProperty('container');
  const dummyElement = document.createElement('div');
  formContainer.appendChild(dummyElement);

  // Dynamically create the iframe form
  butter.elementsApi(function onElementsReady(gb) {
    gb.createElement(dummyElement, 'giving-form', {
      campaign: elementId
    });
    self.formInitialized = true;
  });

  self.donating = true;
};

/**
 * After the modal is closed, reset variables and remove the form iframe
 */
GivebutterDonationForm.prototype.cleanUp = function () {
  const formContainer = this.queryProperty('container');
  formContainer.textContent = '';
  this.formInitialized = false;
};

module.exports = GivebutterDonationForm;
