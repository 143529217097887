'use strict';

const events = require('../core/events');
const fetch = require('../helpers/fetch');
const forgeUiComponent = require('../framework/ui-component');

// Poll every 60 seconds, individual slots will specify the reload interval in seconds
const POLL_INTERVAL = 60000;

// Regions cannot refresh more frequently than this # of minutes
const MIN_REFRESH_RATE = 2;

var RegionRefresh = forgeUiComponent('RegionRefresh', {}, function RegionRefreshConstructor(/* rootElement, opts, builder */) {
  const self = this;

  Object.defineProperty(this, 'slots', {
    get: function getSlots() {
      return this.queryPropertyAll('slot');
    }
  });

  Object.defineProperty(this, 'sourceUrl', {
    get: function getSourceUrl() {
      return window.location.href;
    }
  });

  this.refreshCount = 0;

  // Init polling; individual slots can lower the reload frequency using the `data-refresh-interval` attr
  this.interval = window.setInterval(function () {
    self.refresh();
  }, POLL_INTERVAL);
});

RegionRefresh.prototype.getSlot = function getSlot(name) {
  return this.element.querySelector('.RegionRefresh-slot[data-refresh-slot=' + name + ']');
};

RegionRefresh.prototype.incrementRefreshCount = function incrementRefreshCount() {
  this.refreshCount = this.refreshCount === 60 ? 1 : this.refreshCount + 1;
  return this.refreshCount;
};

RegionRefresh.prototype.refresh = function refresh() {
  var slots = this.slots;
  const self = this;
  const url = this.sourceUrl;
  const refreshCount = this.incrementRefreshCount();

  // If saveData is on, do not refresh content regions automatically
  if (navigator.connection && navigator.connection.saveData) {
    return;
  }

  slots = Array.prototype.filter.call(slots, function (slot) {
    var refreshFrequency = Math.max(slot.getAttribute('data-refresh-interval') || 1, MIN_REFRESH_RATE);
    return (refreshCount % refreshFrequency === 0);
  });

  // Current page has no refreshable regions
  if (!slots.length) {
    return;
  }

  // If any slots in the page are ready to be refreshed, refresh the page
  fetch.get(url, { headers: { 'x-bff-content-update': '1' } }, {
    success: function (dom) {
      self.updateSlots(dom.querySelectorAll('.RegionRefresh-slot'));
    },
    progress: function (amount) {
      self.element.dispatchEvent(new CustomEvent(events.uiRegionRefreshLoading, {
        bubbles: true,
        detail: {
          percentageComplete: amount,
          url: url
        }
      }));
    }
  });
};

RegionRefresh.prototype.updateSlots = function updateSlots(newSlots) {
  const self = this;
  Array.prototype.forEach.call(newSlots, function (newSlot) {
    const slotName = newSlot.getAttribute('data-refresh-slot');
    if (!slotName) {
      return;
    }
    const oldSlot = self.getSlot(slotName);

    // If old slot no-longer exists in the DOM; may have navigated away:
    if (!oldSlot) { return; }

    // If content is unchanged, do not update:
    if (newSlot.isEqualNode(oldSlot)) { return; }

    oldSlot.replaceWith(newSlot);
    newSlot.dispatchEvent(new CustomEvent(events.uiRegionRefreshed, {
      bubbles: true,
      detail: {
        slotName: slotName,
        url: self.sourceUrl
      }
    }));
  });
};

module.exports = RegionRefresh;
