'use strict';

const domready = require('../helpers/domready');
const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const DonationPrompt = forgeUiComponent('DonationPrompt', {
  donating: 'is-donating',
  designated: 'is-designated',
  podcastDesignation: 'is-podcast'
}, function DonationPromptConstructor() {
  const self = this;
  const designationElement = this.element.querySelector('.DonationPrompt-designation') || document.createElement('div');

  Object.defineProperty(this, 'showDesignation', {
    get: function getShowDesignation() {
      const mainContent = document.querySelector('main');
      const mainDesignation = mainContent && mainContent.getAttribute('data-donation-show-designation');
      return mainDesignation || this.element.getAttribute('data-show-designation');
    }
  });

  Object.defineProperty(this, 'djDesignation', {
    get: function getDjDesignation() {
      const mainContent = document.querySelector('main');
      const mainDesignation = mainContent && mainContent.getAttribute('data-donation-dj-designation');
      return mainDesignation || this.element.getAttribute('data-dj-designation');
    }
  });

  Object.defineProperty(this, 'donationFund', {
    get: function getFundDesignation() {
      const mainContent = document.querySelector('main');
      const mainDesignation = mainContent && mainContent.getAttribute('data-donation-fund');
      return mainDesignation || this.element.getAttribute('data-fund');
    }
  });

  Object.defineProperty(this, 'designationText', {
    get: function getDesignation() {
      return designationElement.textContent || '';
    },
    set: function setDesignation(text) {
      if (text) {
        designationElement.textContent = text;
        this.designated = true;
      } else {
        this.designated = false;
      }
    }
  });

  document.addEventListener(events.uiWantsDonation, function () {
    self.updateDesignation();
    self.donating = true;
  });

  // If a donation is positively completed, close the picker
  document.addEventListener(events.donationEnded, function () {
    self.dispatchEvent(events.uiModalDismiss, null, self.element, true);
  });

  this.element.addEventListener(events.uiModalDismiss, function () {
    self.donating = false;
    if (document.location.hash === '#support' || document.location.hash === '#monthly-support') {
      document.location.hash = '';
    }
  });

  domready(function checkForSupportFragment() {
    if (document.location.hash === '#support' || document.location.hash === '#monthly-support') {
      document.dispatchEvent(new CustomEvent(events.uiWantsDonation, { bubbles: true }));
    }
  });
});

DonationPrompt.prototype.updateDesignation = function updateDesignation() {
  this.designationText = this.showDesignation || this.djDesignation || false;
  this.podcastDesignation = (this.donationFund === 'podcast');
};

module.exports = DonationPrompt;
