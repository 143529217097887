'use strict';

/** Integration of Sentry error logging */
const runtimeConfig = require('../config/runtime');
const SentryBrowser = require('@sentry/browser');

function Sentry() {
  SentryBrowser.init({
    dsn: 'https://f2bd072297cd4928be7718175d22eda7@sentry.io/281119',
    debug: false,
    release: runtimeConfig.version,
    environment: runtimeConfig.environment,
    tags: { git_commit: runtimeConfig.jsSha },
    sampleRate: 1.0
  });
}

module.exports = Sentry;
