'use strict';

module.exports = function addScrollEventListener(element, handler) {
  var scrollTicking = false;

  element.addEventListener('scroll', function (e) {
    if (scrollTicking) return;

    window.requestAnimationFrame(function () {
      handler(e);
      scrollTicking = false;
    });
    scrollTicking = true;
  });
};
