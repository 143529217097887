'use strict';

function EventTiming() {
  this.times = [];

  Object.defineProperty(this, 'total', {
    get: function getTotalTime() {
      const total = this.times.map(function (event) {
        return event[1] - event[0];
      }).reduce(function (a, b) { return a + b; }, 0);
      return total > 0 ? total / 1000 : 0;
    }
  });
}

EventTiming.prototype.start = function startEvent() {
  const now = Date.now();
  this.times.push([now, now]);
};

EventTiming.prototype.extend = function extendEvent() {
  const now = Date.now();
  const currentEvent = this.times.pop();
  this.times.push([currentEvent[0], now]);
};

module.exports = EventTiming;
