'use strict';

const forgeUiComponent = require('../framework/ui-component');

/**
 * Because the theme is designed with <base target="_blank"> for handling external links,
 * external code that embeds legacy <a href="javascript:…"> code will trigger new tabs.
 *
 * This component neutralises them.
 */
const JavaScriptLinkHack = forgeUiComponent('JavaScriptLinkHack', {}, function JavaScriptLinkHackConstructor() {
  document.addEventListener('click', function (e) {
    const linkTarget = e.target && e.target.closest && e.target.closest('A');

    // If there's no link target, ignore:
    if (!linkTarget) { return; }

    // Some other component cancelled the event
    if (e.defaultPrevented) { return; }

    // If the link begins 'javascript:' prevent default to stop the link being followed.
    /* eslint-disable no-script-url */
    if (linkTarget.href.indexOf('javascript:') === 0) {
      e.preventDefault();
    }
    /* eslint-enable no-script-url */
  }, false);
});

// Unlike other UIComponents, links are initialized within the element root,
// so we always return one component that may or may not contain links later.
//
// TODO: DRY: Make this a `Discoverable` mix-in
JavaScriptLinkHack.discover = function (root) {
  return {
    components: [new JavaScriptLinkHack(root)],
    errors: []
  };
};

module.exports = JavaScriptLinkHack;
