'use strict';

/* Development helpers */
const runtime = require('../config/runtime');

function Development() {
  /* eslint-disable no-console */
  if (!console || !console.info) {
    return;
  }

  const envIcon = runtime.environment === 'prod' ? '🚦' : '🚧';

  console.info('Server Info');
  console.info(envIcon + ' You\'re viewing BFF.fm on ' + runtime.environment);
  console.info('🕝 ' + runtime.serverDeployTime);
  console.info('🔀 ' + runtime.serverBranch);
  console.info('#️⃣ ' + runtime.serverSha);

  console.info('Theme Info');
  console.info('❄️ ' + runtime.version);
  console.info('#️⃣ ' + runtime.jsSha);

  /* eslint-enable no-console */
}

module.exports = Development;
