'use strict';

const events = require('./events');
const fetch = require('../helpers/fetch');

function Navigation(root) {
  var listenTo = root.addEventListener ? root : root.body;
  var contentSelector = 'main';
  var initalPageTitle = document.querySelector('title').textContent;

  Object.defineProperty(this, 'rootElement', {
    get: function () { return root; }
  });

  Object.defineProperty(this, 'contentSelector', {
    get: function () { return contentSelector; },
    set: function (newSelector) { contentSelector = newSelector; }
  });

  listenTo.addEventListener('click', this.handleNavigationEvent.bind(this));
  window.addEventListener('popstate', this.handleHistoryEvent.bind(this));

  // Push initial state
  window.history.pushState({ url: window.location.href }, initalPageTitle, window.location.href);
}

Navigation.prototype.handleNavigationEvent = function (e) {
  var baseLocation = window.location.protocol + '//' + window.location.host;
  var linkTarget = e.target && e.target.closest && e.target.closest('A');

  // Some other component cancelled the event
  if (e.defaultPrevented) { return; }

  // If there's no link target, ignore:
  if (!linkTarget) { return; }

  // If the link points to a different domain, ignore:
  if (linkTarget.href.indexOf(baseLocation) !== 0) { return; }

  // If the link is to be opened in a new window, ignore:
  // HACK: We use explicit `getAttribute` rather than node.target as Firefox returns the
  // inherited value of <base target=_blank>
  if (linkTarget.getAttribute('target') === '_blank') { return; }

  // TODO: If link is a fragment on the same page

  // If the user is holding down a modifier key for new tab/download behaviour, ignore:
  if (e.ctrlKey || e.shiftKey || e.metaKey || e.altKey) { return; }

  e.preventDefault();

  this.navigateTo(linkTarget.href);
};

Navigation.prototype.handleHistoryEvent = function (e) {
  var previousUrl = e.state && e.state.url;

  if (previousUrl) {
    this.navigateTo(previousUrl, true, true);
  }
};

Navigation.prototype.navigateTo = function (url, useCache, didPopState) {
  var self = this;

  this.rootElement.dispatchEvent(new CustomEvent(events.navigationRequestStarted, {
    bubbles: true, detail: { url: url }
  }));

  // Cancel any request that's already happening
  if (this.currentRequest) {
    this.currentRequest.abort();
    this.currentRequest = undefined;
  }

  // If we clicked a link to *the same page*, don't do anything (but still trigger events)
  if (!didPopState && url === window.location.href) {
    self.rootElement.dispatchEvent(new CustomEvent(events.navigationRequestEnded, {
      bubbles: true,
      detail: {
        didNavigate: true,
        url: url
      }
    }));
    return;
  }

  // If we're navigating away, add the current content to the cache
  self.cacheCurrentPage();

  // Attempt to pull a previous page DOM from cache
  if (useCache && this.updatePageContentFromCache(url)) {
    return;
  }

  // Finally, fetch fresh content from the web
  this.currentRequest = fetch.get(url, { headers: { 'x-bff-content-update': '1' } }, {
    success: function (dom) {
      var newTitle = dom.querySelector('title');
      var newMain = dom.querySelector(self.contentSelector);
      var main;
      // In case there was a redirect, get the real location:
      var finalUrl = self.currentRequest.responseURL;

      // If the returned page doesn't match this template, end and hard-refresh:
      if (!newTitle || !newMain) {
        main = document.querySelector(self.contentSelector);
        (main || document.body).dispatchEvent(new CustomEvent(events.navigationRequestEnded, {
          bubbles: true,
          detail: {
            didNavigate: false,
            url: finalUrl
          }
        }));
        window.location = finalUrl;
        return;
      }
      if (!useCache) {
        window.history.pushState({ url: finalUrl }, newTitle.textContent, finalUrl);
      }

      self.updatePageContent(finalUrl, newMain, newTitle.textContent);
    },
    failure: function () {
      // If this failed in any way, then fall back to navigating normally
      var main = document.querySelector(self.contentSelector);
      main.dispatchEvent(new CustomEvent(events.navigationRequestEnded, {
        bubbles: true,
        detail: {
          didNavigate: false,
          url: url
        }
      }));
      window.location = url;
    },
    progress: function (amount) {
      var main = document.querySelector(self.contentSelector);
      main.dispatchEvent(new CustomEvent(events.navigationRequestProgress, {
        bubbles: true,
        detail: {
          percentageComplete: amount,
          url: url
        }
      }));
    }
  });
};

Navigation.prototype.cacheCurrentPage = function () {
  // TODO: Complete content caching support
  return false;
  // const url = window.location.href;
  // const title = document.title;
  // const content = this.rootElement.querySelector(this.contentSelector);
};

Navigation.prototype.updatePageContent = function (url, newContent, title) {
  var main = this.rootElement.querySelector(this.contentSelector);
  document.title = title;
  main.parentNode.replaceChild(newContent, main);

  newContent.dispatchEvent(new CustomEvent(events.navigationRequestEnded, {
    bubbles: true,
    detail: {
      didNavigate: true,
      url: url
    }
  }));
};

// TODO: Complete content caching support
Navigation.prototype.updatePageContentFromCache = function (/* url */) {
  return false;
};

module.exports = Navigation;
