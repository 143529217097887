'use strict';

/** SlideDeck
  *
  * A UI controller for paged slidedeck/carousel UI.
  */
const forgeUiComponent = require('../framework/ui-component');
const addScrollEventListener = require('../helpers/addscrolleventlistener');

var SlideDeck = forgeUiComponent('SlideDeck', {}, function SlideDeckConstructor() {
  var self = this;
  const slides = self.element.querySelector('.SlideDeck-slides');

  const paginationElement = self.element.querySelector('.SlideDeck-pagination');
  const nextButton = self.element.querySelector('.SlideDeck-nextSlide');
  const previousButton = self.element.querySelector('.SlideDeck-previousSlide');

  Object.defineProperty(this, 'slidesContainer', {
    get: function getSlidesElement() { return slides; }
  });

  Object.defineProperty(this, 'paginationContainer', {
    get: function getPaginationElement() { return paginationElement; }
  });

  if (paginationElement) {
    self.generatePagination();
  }

  if (nextButton) {
    nextButton.addEventListener('click', function () {
      var position = slides.scrollLeft;
      var size = slides.offsetWidth;
      slides.scrollTo({
        top: 0,
        left: position + size,
        behavior: 'smooth'
      });
    });
  }

  if (previousButton) {
    previousButton.addEventListener('click', function () {
      var position = slides.scrollLeft;
      var size = slides.offsetWidth;
      slides.scrollTo({
        top: 0,
        left: position - size,
        behavior: 'smooth'
      });
    });
  }

  addScrollEventListener(slides, function () {
    var count = slides.children.length;
    requestAnimationFrame(function () {
      var position = slides.scrollLeft / slides.scrollWidth;
      self.highlightSelectedPage(Math.floor(position * count));
    });
  });
});

SlideDeck.prototype.generatePagination = function generatePagination() {
  var self = this;
  if (!self.paginationContainer) { return; }

  const pips = document.createDocumentFragment();

  Array.prototype.forEach.call(self.slidesContainer.children, function (child, index) {
    var li = document.createElement('li');
    var button = document.createElement('div');

    li.className = 'SlideDeck-paginationItem';

    button.setAttribute('data-target', index);
    button.className = 'Pip';
    button.textContent = index;

    li.appendChild(button);
    pips.appendChild(li);
  });

  requestAnimationFrame(function () {
    self.paginationContainer.innerHTML = '';
    self.paginationContainer.appendChild(pips);
    self.highlightSelectedPage(0);
  });
};

SlideDeck.prototype.highlightSelectedPage = function highlightSelectedPage(selectedIndex) {
  var self = this;
  if (self.paginationContainer) {
    Array.prototype.forEach.call(self.paginationContainer.querySelectorAll('.Pip'), function (pip, index) {
      requestAnimationFrame(function () {
        pip.classList.toggle('Pip--selected', index === selectedIndex);
      });
    });
  }
};

module.exports = SlideDeck;
