'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

const AddReminder = forgeUiComponent('AddReminder', {
  subscribing: 'is-subscribing'
}, function AddReminderConstructor() {
  const self = this;
  const subscribeButton = this.queryProperty('subscribeButton');

  this.element.addEventListener(events.uiModalDismiss, function () {
    self.subscribing = false;
  });

  subscribeButton.addEventListener('click', function openSubscribeDialog(e) {
    e.preventDefault();
    self.subscribing = true;
  });
});

module.exports = AddReminder;
