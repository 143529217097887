'use strict';

const forgeUiComponent = require('../framework/ui-component');

const POPUP_WIDTH = 520;
const POPUP_HEIGHT = 550;

var SharePopup = forgeUiComponent('SharePopup', {}, function SharePopupConstructor() {
  var self = this;

  this.element.addEventListener('click', function (e) {
    e.preventDefault();
    self.openURL(self.finalizeLink(self.element.href));
  });
});

SharePopup.prototype.finalizeLink = function finalizeLink(url) {
  // Live update with the current URL
  return url.replace(encodeURIComponent('{{link}}'), encodeURIComponent(window.location.href));
};

SharePopup.prototype.isWebTarget = function isWebTarget() {
  return this.element.href.indexOf('http') === 0;
};

SharePopup.prototype.openURL = function openURL(url) {
  if (this.isWebTarget()) {
    window.open(url, null, this.windowOptions());
  } else {
    window.location.href = url;
  }
};

SharePopup.prototype.windowOptions = function windowOptions() {
  const options = {
    width: POPUP_WIDTH,
    height: POPUP_HEIGHT,
    personalbar: 0,
    toolbar: 0,
    location: 1,
    scrollbars: 1,
    resizable: 1,
    left: (Math.floor(window.outerWidth / 2) - Math.floor(POPUP_WIDTH / 2)) + window.screenLeft,
    top: (Math.floor(window.outerHeight / 2) - Math.floor(POPUP_HEIGHT / 2)) + window.screenTop
  };

  return Object.keys(options).map(function (key) {
    return key + '=' + options[key];
  }).join(',');
};

module.exports = SharePopup;
