'use strict';

/* Fetch content of Creek /page */

const events = require('../core/events');
const fetch = require('../helpers/fetch');

const pages = {
  fetch: function fetchPage(path) {
    fetch.get(path, { headers: { 'x-bff-content-update': '1' } }, {
      success: function (domResponse) {
        document.dispatchEvent(new CustomEvent(events.dataHtmlPage, {
          detail: domResponse
        }));
      },
      failure: function currentTrackFailed(e) {
        /* eslint-disable no-console */
        console.warn(e);
        /* eslint-enable no-console */
      }
    });
  }
};

module.exports = pages;
