'use strict';

const events = require('../core/events');
const forgeUiComponent = require('../framework/ui-component');

var SiteSearch = forgeUiComponent('SiteSearch', {}, function SiteSearchConstructor() {
  const self = this;
  const input = this.element.querySelector('.SiteSearch-box') || document.createElement('input');

  this.element.addEventListener('submit', function (e) {
    e.preventDefault();
    e.stopPropagation();

    const query = input.value;

    if (!query) { return; }

    const fullQuery = [query, self.extraQueryString].join(' ');
    const searchUrl = [
      self.element.action,
      '?q=',
      encodeURIComponent(fullQuery)
    ].join('');

    window.open(searchUrl);

    document.dispatchEvent(new CustomEvent(events.uiDidSearch, {
      detail: {
        query: query
      }
    }));
  });

  this.element.addEventListener('click', function (e) {
    // HACK: Prevent main menu closing when focusing on search box
    e.stopPropagation();
  });

  Object.defineProperty(this, 'extraQueryString', {
    get: function getTrackId() { return this.element.getAttribute('data-extra-query'); }
  });
});

module.exports = SiteSearch;
