'use strict';

const forgeUiComponent = require('../framework/ui-component');
const Clipboard = require('clipboard');

const SubscribeDialog = forgeUiComponent('SubscribeDialog', {}, function SubscribeDialogConstructor() {
  const urlElement = this.element.querySelector('.SubscribeDialog-url') || document.createElement('samp');

  Object.defineProperty(this, 'feedUrl', {
    get: function getFeedUrl() {
      return urlElement.textContent.trim();
    }
  });

  this.bindClipboard();
});

SubscribeDialog.prototype.bindClipboard = function bindClipboardAction() {
  const self = this;
  const copyBtn = this.element.querySelector('.SubscribeDialog-copyUrl');

  if (Clipboard.isSupported()) {
    const clippy = new Clipboard(copyBtn, {
      container: this.element,
      text: function getTextForClipboard() { return self.feedUrl; }
    });
    clippy.on('success', function successfullyCopied() {
      /* eslint-disable no-alert */
      window.alert('URL copied!');
      /* eslint-enable no-alert */
    });
    clippy.on('error', function copyFailed() {
      self.copyFallback();
    });
  } else {
    copyBtn.addEventListener('click', function clipboardUnsupported() {
      self.copyFallback();
    });
  }
};

SubscribeDialog.prototype.copyFallback = function legacyClickCopyButton() {
  /* eslint-disable no-alert */
  window.prompt('Copy the URL below:', this.feedUrl);
  /* eslint-enable no-alert */
};

module.exports = SubscribeDialog;
