'use strict';

const forgeUiComponent = require('../framework/ui-component');
const timeFormatter = require('../helpers/formattime');
const bffConfig = require('../config/bff');
const events = require('../core/events');

const ON_AIR_TEXT = 'On Air';

var PlaybackInfo = forgeUiComponent('PlaybackInfo', {
  empty: 'is-empty'
}, function PlaybackInfoConstructor(el, options, builder) {
  var self = this;
  var trackTitle = el.querySelector('.PlaybackInfo-title') || document.createElement('div');
  var trackSubtitle = el.querySelector('.PlaybackInfo-subTitle') || document.createElement('div');
  var sourceTitle = el.querySelector('.PlaybackInfo-sourceTitle') || document.createElement('a');
  var sourceCaption = el.querySelector('.PlaybackInfo-sourceCaption') || document.createElement('div');
  var sourceImage = el.querySelector('.PlaybackInfo-art') || document.createElement('div');

  builder.defineAttributeProperty('sourceType', 'data-source');

  // Expose properties
  Object.defineProperty(this, 'artSrc', {
    get: function getArt() { return sourceImage.src; },
    set: function setArt(src) {
      // HACK: FIX: This code doesn't support <picture>/srcset yet, so we're hard-coding to 2x retina as a compromise.
      const ioSrc = [src, '?', 'frame=1&format=jpeg&auto=webp&width=360&height=360&fit=crop'].join('');

      if (sourceImage.src === ioSrc) { return; }

      sourceImage.src = src ? ioSrc : '';
      this.missingImage = !src;
    }
  });

  Object.defineProperty(this, 'title', {
    get: function getTitle() { return trackTitle.textContent; },
    set: function setTitle(title) {
      if (trackTitle.textContent === title) { return; }

      requestAnimationFrame(function () {
        trackTitle.textContent = title || '';
        trackTitle.title = title || '';
      });
    }
  });

  Object.defineProperty(this, 'subTitle', {
    get: function getSubTitle() { return trackSubtitle.textContent; },
    set: function setSubTitle(subTitle) {
      if (trackSubtitle.textContent === subTitle) { return; }

      requestAnimationFrame(function () {
        trackSubtitle.textContent = subTitle || '';
        trackSubtitle.title = subTitle || '';
      });
    }
  });

  Object.defineProperty(this, 'showName', {
    get: function getShowName() { return sourceTitle.textContent; },
    set: function setShowName(showName) {
      if (sourceTitle.textContent === showName) { return; }

      requestAnimationFrame(function () {
        sourceTitle.textContent = showName || '';
        sourceTitle.title = showName || '';
      });
    }
  });

  Object.defineProperty(this, 'showUrl', {
    get: function getShowName() { return sourceTitle.href; },
    set: function setShowName(url) {
      if (sourceTitle.href === url) { return; }
      sourceTitle.href = url || '';
    }
  });

  Object.defineProperty(this, 'showCaption', {
    get: function getShowCaption() { return sourceCaption.textContent; },
    set: function setShowCaption(caption) {
      const newCaption = this.liveStream ? ON_AIR_TEXT : caption || '';

      if (sourceCaption.textContent === newCaption) { return; }

      requestAnimationFrame(function () {
        sourceCaption.textContent = newCaption;
      });
    }
  });

  // Listen for audio playback changes
  if (self.sourceType === 'live') {
    document.addEventListener(events.audioSourceMetadataUpdate, function (e) {
      var detail = e.detail && e.detail;
      if (!detail || detail.src !== bffConfig.liveStreamUrl) {
        return;
      }
      self.updateContent(detail.metadata);
    });
  }

  if (self.sourceType === 'archive') {
    document.addEventListener(events.audioSourceMetadataUpdate, function (e) {
      var detail = e.detail && e.detail;
      if (!detail || detail.src === bffConfig.liveStreamUrl) {
        return;
      }
      self.updateContent(detail.metadata);
    });

    document.addEventListener(events.audioSourceTimeUpdate, function (e) {
      var detail = e.detail && e.detail;
      if (!detail || detail.src === bffConfig.liveStreamUrl) {
        return;
      }

      self.updateProgress({
        duration: detail.duration,
        time: detail.time
      });
    });
  }

  if (self.sourceType === 'page') {
    // Listen for navigation changes for page content
    document.addEventListener(events.pageAudioMetadataUpdate, function (e) {
      var detail = e.detail && e.detail;
      if (!detail) {
        return;
      }
      self.updateContent(detail.metadata);
    });

    document.addEventListener(events.pageAudioMetadataCleared, function () {
      self.clearContent();
    });
  }
});

PlaybackInfo.prototype.updateContent = function updateContent(metadata) {
  this.empty = !metadata.title;

  if (!metadata.title) {
    return;
  }

  this.title = metadata.title;
  this.subTitle = metadata.artist;

  this.showName = metadata.show;
  this.showUrl = metadata.showUrl;

  // HACK: We're duplicating setting the text for ON AIR due to being out of sync
  // updating the real element content. Might need some sort of buffer for that.
  this.showCaption = metadata.isStream ? ON_AIR_TEXT : metadata.duration;

  this.artSrc = metadata.image;
  this.liveStream = metadata.isStream;
};

PlaybackInfo.prototype.clearContent = function clearContent() {
  this.empty = true;
  this.title = false;
  this.subTitle = false;
  this.showName = false;
  this.showUrl = false;
  this.showCaption = false;
  this.artSrc = false;
  this.liveStream = false;
};

PlaybackInfo.prototype.updateProgress = function updateProgress(metadata) {
  this.showCaption = (metadata.duration && (metadata.duration !== Infinity))
    ? [timeFormatter.formatTime(metadata.time), timeFormatter.formatTime(metadata.duration)].join('/')
    : timeFormatter.formatTime(metadata.time);
};

module.exports = PlaybackInfo;
